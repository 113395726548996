import * as React from "react"
import { Link } from "gatsby"

// importing layout
import { Main } from "../layouts/main"

// Importing styles
import "./styles/default.scss"

// Importing Bootstrap components
import { Button, Container, Row } from "react-bootstrap"

export default function NotFoundPage() {
  return (
    <Main>
      <div className="default page-not-found">
        <div className="intro">
          <Container>
            <h1 className="shout">Page Not Found</h1>
            <p className="talk">
              Sorry, we couldn't find what you were looking for.
            </p>
            <Link to="/">
              <Button variant="secondary">Back to home</Button>
            </Link>
          </Container>
        </div>
      </div>
    </Main>
  )
}
